import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Container, Text } from "@chakra-ui/react";
import Layout from "../layouts/Layout";

export const NotFoundPageQuery = graphql`
  query {
    heroBg: file(relativePath: { eq: "hero-bg-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
      extension
      publicURL
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Container maxW="container.md" p="80px 30px">
      <Text fontSize="20px" textAlign="center">
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Home Page</Link>.
      </Text>
    </Container>
  );
};

NotFoundPage.getLayout = (page) => (
  <Layout
    {...page.props}
    pageTitle="Page Not Found"
    backgroundImage={page.props.data?.heroBg}
  >
    {page}
  </Layout>
);

export default NotFoundPage;
